<template>
  <div style="position: relative; display: inline-block; width: 100%">
    <CInput
      :label="label"
      :value="spanText"
      :disabled="disabled"
      :invalid-feedback="invalidFeedback"
      :is-valid="isValid"
      :horizontal="horizontal"
      @change="updateValue"
    >
      <template #append-content>
        <CIcon name="cil-calendar" @click.native="togglePicker" />
      </template>
    </CInput>
    <transition name="slide-fade" mode="out-in">
      <div
        v-if="open"
        v-on-clickaway="clickAway"
        class="daterangepicker dropdown-menu ltr"
        :class="pickerStyles()"
      >
        <div class="calendar left">
          <div class="calendar-table">
            <Calendar
              :month-date="monthDate"
              :locale="locale"
              :start="internalValue"
              :end="internalValue"
              @nextMonth="nextMonth"
              @prevMonth="prevMonth"
              @dateClick="dateClick"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import Calendar from './Calendar.vue';
import { nextMonth, prevMonth } from './util';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'DatePicker',
  components: { Calendar },
  mixins: [clickaway],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    localeData: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: [Object, Date, Number, String],
      default() {
        return null;
      },
    },
    opens: {
      type: String,
      default: 'center',
    },
    label: {
      type: String,
      required: false,
      default: 'Date',
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: null,
    },
    isValid: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  data() {
    let defaultLocale = {
      direction: 'ltr',
      format: moment.localeData().longDateFormat('L'),
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: moment.localeData().firstDayOfWeek(),
    };

    // let data = { locale: _locale }
    let data = { locale: { ...defaultLocale, ...this.localeData } };

    let inputValue = this.value;
    if (inputValue) {
      inputValue = moment(inputValue);
      data.monthDate = new Date(inputValue.toDate());
    } else {
      data.monthDate = new Date();
    }

    data.inSelection = false;
    data.open = false;

    // update day names order to firstDay
    if (data.locale.firstDay !== 0) {
      let iterator = data.locale.firstDay;
      while (iterator > 0) {
        data.locale.daysOfWeek.push(data.locale.daysOfWeek.shift());
        iterator--;
      }
    }

    // data.internalValue = this.value ? new Date(this.value) : null;
    data.internalValue = inputValue ? new Date(inputValue.toDate()) : null;
    return data;
  },
  computed: {
    canClear() {
      return !!this.internalValue;
    },
    nextMonthDate() {
      return nextMonth(this.monthDate);
    },
    spanText() {
      if (this.internalValue) {
        return this.internalValue.toLocaleDateString();
      }

      return null;
    },
  },
  watch: {
    value(v) {
      if (!v) {
        this.internalValue = null;
        this.monthDate = new Date();
      } else {
        this.internalValue = moment(v, 'YYYY-MM-DD').toDate();
        this.monthDate = moment(v, 'YYYY-MM-DD').toDate();
      }
    },
  },
  methods: {
    nextMonth() {
      this.monthDate = nextMonth(this.monthDate);
    },
    prevMonth() {
      this.monthDate = prevMonth(this.monthDate);
    },
    dateClick(value) {
      this.internalValue = new Date(value);
      this.clickedApply();
    },
    togglePicker() {
      this.open = !this.open;
    },
    pickerStyles() {
      return {
        'show-calendar': this.open,
        opensright: this.opens === 'right',
        opensleft: this.opens === 'left',
        openscenter: this.opens === 'center',
      };
    },
    clickedApply() {
      this.open = false;
      this.$emit('change', this.internalValue);
      this.$emit('input', this.internalValue);
    },
    clickedClear() {
      this.internalValue = null;
      this.clickedApply();
    },
    clickAway() {
      if (this.open) {
        this.open = false;
      }
    },
    updateValue(val) {
      let oldValue = this.internalValue;
      if (val) {
        this.internalValue = new Date(val);
      } else {
        this.internalValue = null;
      }
      if (oldValue !== this.internalValue) {
        this.$emit('change', this.internalValue);
        this.$emit('input', this.internalValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control[readonly] {
  background-color: #fff;
  border: 1px solid #c2cfd6;
  cursor: pointer;
}
</style>

<style lang="scss">
@import './daterangepicker.scss';

.dr-picker-group {
  .btn.btn-outline-secondary {
    color: #000;
  }
}
.range_inputs {
  margin-bottom: 10px;
}

.reportrange-text {
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 100%;
}

.daterangepicker.show-calendar {
  display: inline-flex;
}

// .daterangepicker .ranges {
//   width: 160px;
// }

.ranges {
  width: 130px;
  margin-left: 1rem;
}

.show-calendar {
  display: block;
  width: auto;
}

.daterangepicker {
  &.opensleft,
  &.openscenter,
  &.opensright {
    top: 35px;
    padding: 1.75rem;
  }

  &.opensright {
    left: calc(50% + 10px);
    right: auto;
  }

  &.openscenter {
    right: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &.opensleft {
    right: calc(50% + 10px);
    left: auto;
  }

  .ranges {
    width: 160px;
  }
}
/*
div.daterangepicker.opensleft {
  top: 35px;
  right: 10px;
  left: auto;
}

div.daterangepicker.openscenter {
  top: 35px;
  right: auto;
  left: 50%;
  transform: translate(-50%, 0);
}

div.daterangepicker.opensright {
  top: 35px;
  left: 10px;
  right: auto;
}
*/
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
